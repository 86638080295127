<template>
  <div>
    <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
    <div v-if="getCompanyLoadingState && !error" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section v-if="getCompanyLoadingState && !error">
      <h3>Companies</h3>
      <table class="table table-hover p-table" id="companyTable">
        <thead>
          <tr>
            <th>Company name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="companies in getCompanies" :key="companies.id">
            <td class="p-name">
              {{ companies.name }}
            </td>
            <td><button>Add user</button></td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "companies",
  methods: {
    ...mapActions(["fetchCompanies"])
  },
  computed: mapGetters(["getCompanies", "getCompanyLoadingState"]),
  data() {
    return {
      searchString: "",
      companies: [],
      error: null
    };
  },
  created() {
    this.fetchCompanies()
      .then(() => {
        this.companies = this.getCompanies;
      })
      .catch(erro => {
        this.error = erro;
      });
  }
};
</script>

<style></style>
